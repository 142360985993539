import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState } from "react";

function App() {

  var userData = {
    email: "",
    name: "",
    surname: "",
    username: "",
    courses: []
  };

  const [validated, setValidated] = useState(false);

  const handleNamelChange = (e) => {
    userData.name = e.target.value;
  }

  const handleSurnameChange = (e) => {
    userData.surname = e.target.value;
  }

  const handleEmailChange = (e) => {
    userData.email = e.target.value;
  }

  const handleUsernameChange = (e) => {
    userData.username = e.target.value;
  }

  const handleCourseChange = (e) => {

    var value = e.target.attributes.getNamedItem("course-id").value;
    var position = userData.courses.indexOf(value);

    if (position === -1) {
        userData.courses.push(value);
    } else {
      userData.courses.splice(position, 1);
    }

    console.log(userData.courses);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    fetch('https://api.youcanmath.it/register', {
      mode : 'no-cors',
      method: 'POST',
      // We convert the React state to JSON and send it as the POST body
      body: JSON.stringify(userData)
    }).then(function(response) {
      console.log(response)
    }).catch(function(error) {
      console.log(error);
  });
  };

  return (
    <div className="App">
      <Container
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
      }}>
        <Row>
          <Col className="form">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group as={Row} controlId="formHorizontalEmail" className="header">
                <Form.Label>
                  <h1>Crea Nuovo Utente</h1>
                  <hr className="hr-header" />
                </Form.Label>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={5}>
                  Nome
                </Form.Label>
                <Col sm={7}>
                <Form.Control required type="text" placeholder="Nome Utente" onChange={handleNamelChange} />
                <Form.Control.Feedback type="invalid">
                    Nome obbligatorio.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={5}>
                  Cognome
                </Form.Label>
                <Col sm={7}>
                  <Form.Control type="text" placeholder="Cognome Utente" onChange={handleSurnameChange} />
                  <Form.Control.Feedback type="invalid">
                    Cognome obbligatorio.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={5}>
                  Email
                </Form.Label>
                <Col sm={7}>
                  <Form.Control type="text" placeholder="Email Utente" required onChange={handleEmailChange} />
                  <Form.Control.Feedback type="invalid">
                    Email obbligatoria.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={5}>
                  Username
                </Form.Label>
                <Col sm={7}>
                  <Form.Control type="text" placeholder="Username Utente" required onChange={handleUsernameChange} />
                  <Form.Text className="text-muted">
                    Assicurati che sia univoco.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    Username obbligatorio.
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={5}>
                  Corsi
                </Form.Label>
                <Col sm={7}>
                  <Form.Check inline type="checkbox" course-id="1" label="YCM1" onChange={handleCourseChange} />
                  <Form.Check inline type="checkbox" course-id="2" label="YCM2" onChange={handleCourseChange} />
                  <Form.Check inline type="checkbox" course-id="3" label="YCM3" onChange={handleCourseChange} />
                  <Form.Check inline type="checkbox" course-id="4" label="YCM4" onChange={handleCourseChange} />
                  <Form.Check inline type="checkbox" course-id="5" label="YCM5" onChange={handleCourseChange} />
                  <Form.Check inline type="checkbox" course-id="6" label="YCME" onChange={handleCourseChange} />
                </Col>
              </Form.Group>
              <Button variant="primary" type="submit">
                Crea Utente
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
